<template>
	<div>
		force log out page.
	</div>
</template>

<script>

export default {
  data() {
    return {
     
    };
  },
	mounted() {
		this.signOut()
	},
  methods: {
    signOut() {
      this.$auth.signOut()
        .then(() => this.redirectWhenUserStateIsNotLoggedInAnymore())
    },
    redirectWhenUserStateIsNotLoggedInAnymore() {
      if (this.user.loggedIn) {
        setTimeout(() => this.redirectWhenUserStateIsNotLoggedInAnymore(), 10)
      } else {
        this.$router.push('/login')
      }
    },
	}
}

</script>