import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Home from './views/Home.vue'
import Login from './views/Login.vue'
import ForceLogout from './views/ForceLogout.vue'
import CompleteLogin from './views/CompleteLogin.vue'

import Dashboard from './views/Dashboard.vue'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitForUserToBeLoaded() {
  const user = store.getters.user
  if (user.loggedIn === null) {
    await sleep(100);
    await waitForUserToBeLoaded()
  }
}


const checkIsNotLoggedIn = async (to, from, next) => {
  await waitForUserToBeLoaded()
  const user = store.getters.user
  if (user.loggedIn) {
    return next('/dashboard')
  } else {
    return next()
  }
}

const checkIsLoggedIn = async (to, from, next) => {
  await waitForUserToBeLoaded()
  const user = store.getters.user
  if (user.data) {
    // Done.
    return next()
  }
  if (user.loggedIn === null) {
    // wait for auth data to be loaded
    return setTimeout(() => checkIsLoggedIn(to, from, next), 100)
  } else if (user.loggedIn) {
    if (!user.data) return setTimeout(() => checkIsLoggedIn(to, from, next), 100)
  } else {
    return next('/')
  }
}

const routes = [
  // Only accessible not logged in
  {
    path: '/',
    component: Home,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/complete-login',
    component: CompleteLogin,
    beforeEnter: checkIsNotLoggedIn,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: checkIsLoggedIn,
  },
  {
    path: '/force-logout',
    component: ForceLogout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next();
})

export default router
