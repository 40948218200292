<template>
	<div>
		<v-layout row wrap justify-center>
			<v-flex xs6 text-center>
				<v-layout row wrap>
					<v-flex xs12 v-if="isSendLinkSuccess">
						<div>An email was sent to {{ email }}</div>
						<div>Go to your inbox and click on the link to authenticate.</div>
					</v-flex>
					<v-flex xs12 v-else-if="isSendLinkPending">
						<v-progress-linear/>
					</v-flex>
					<v-flex xs12 v-else>
						<v-form ref="form" @submit.prevent="sendSignInLink">
							<v-text-field dense label="Your professional email" v-model="email" :rules="emailRules" required outlined class="form-input"></v-text-field>
							<v-btn type="submit" color="primary" block large>Get authentication link by email</v-btn>
						</v-form>
						<div v-if="errorMessage" class="red--text mt-2">
							{{ errorMessage }}
						</div>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>

export default {
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email is not valid",
      ],
      errorMessage: null,
      isSendLinkPending: false,
			isSendLinkSuccess: false
    };
  },
	mounted() {
		if (this.$route.params.email) {
			this.email = this.$route.params.email
			this.sendSignInLink()
		}
	},
  methods: {
    sendSignInLink() {
      this.errorMessage = null
      if (!this.$refs.form.validate()) return;

			const actionCodeSettings = {
				url: `${window.location.origin}/complete-login`,
				handleCodeInApp: true,
			}

			this.isSendLinkPending = true
			this.$auth.sendSignInLinkToEmail(this.email, actionCodeSettings)
				.then(() => {
					// The link was successfully sent. Inform the user.
					// Save the email locally so you don't need to ask the user for it again
					// if they open the link on the same device.
					window.localStorage.setItem('emailForSignIn', this.email);
					this.isSendLinkPending = false
					this.isSendLinkSuccess = true
				})
				.catch((error) => {
					this.isSendLinkPending = false
					this.isSendLinkSuccess = false
					this.errorMessage = error.message;
				});

		}
	}
}

</script>