<template>
  <div>
    <div v-if="isShowingLoader" justify-center>
      <v-layout wrap justify-center mt-6>
        <v-flex xs6 text-center mt-12>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <v-layout wrap justify-center mt-12>
        <v-flex xs12 sm8>
          <h1>Validation of your email address</h1>
          <v-layout wrap mt-2 mb-2 justify-center>
            <v-flex xs8 text-center>
              <div v-if="isShowingEmailForm">
                <v-card class="px-12 py-6">
                  <div>
                    <v-text-field v-model="email" outlined type="email" label="Your email address"/>
                    <v-btn @click="completeLogin" class="secondary" :disabled="!isEmailOk">Log in</v-btn>
                  </div>
                </v-card>
              </div>
              <div v-if="errorMsg" class="mt-3">
                <v-card class="px-12 py-6">
                  <div>{{ errorMsg }}</div>
                </v-card>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: '',
    isShowingLoader: false,
    isShowingEmailForm: false,
    errorMsg: '',
  }),
  computed: {
    isEmailOk() {
      return this.email && /.+@.+\..+/.test(this.email)
    },
  },
  mounted() {
    this.automaticallyCheckLogin()
  },
  methods: {
    async automaticallyCheckLogin() {
      this.isShowingLoader = true
      // Confirm the link is a sign-in with email link.
      if (this.$auth.isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        this.email = window.localStorage.getItem('emailForSignIn');
				console.log('A', this.email)
        if (this.email) {
          await this.completeLogin()
        } else {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
					console.log('B no email')
          this.isShowingEmailForm = true
          this.isShowingLoader = false
        }
      }
    },
    async completeLogin() {
      this.isShowingLoader = true
      this.errorMsg = ''
      this.isShowingEmailForm = false

      // The client SDK will parse the code from the link for you.
      this.$auth.signInWithEmailLink(this.email, window.location.href)
        .then((result) => { // eslint-disable-line
          // Clear email from storage.
          try { window.localStorage.removeItem('emailForSignIn'); } catch(err) {}  // eslint-disable-line
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
					console.log('Success !')
					this.$router.push('/dashboard')
        })
        .catch((error) => {
          console.error('error', error); // eslint-disable-line
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          
          if (error.code === 'auth/expired-action-code') {
            this.isShowingLoader = false
            this.errorMsg = "This link has expired."
            setTimeout(() => {
              this.$router.push('/login')
            }, 2500)
          }
          if (error.code === 'auth/invalid-email') {
            this.isShowingLoader = false
            this.isShowingEmailForm = true
            this.errorMsg = "Type the email address at which you received the authentication email."
          }
          else {
            this.isShowingLoader = false
            this.errorMsg = "An error occurred."
            setTimeout(() => {
              this.$router.push('/login')
            }, 2500)
          }
        });
    }
  }
}
</script>
