import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: colors.grey.darken1,
        // background: colors.grey.darken1,//colors.amber.lighten4,//'#f4ede6',
        error: colors.black
      },
    },
  },
});
