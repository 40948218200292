<template>
	<div>
		<v-layout row wrap>
			<v-flex xs12>
				hello
			</v-flex>
		</v-layout>
	</div>
</template>

<script>


export default {
  data() {
    return {

    };
  },
	mounted() {
		
	},
  methods: {

	}
}

</script>