import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    user: {
      loggedIn: null, // null means we don't know yet if is loggedIn
      data: null,
    }
  },
  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    setIsUserLoggedIn({ commit }, loggedIn) {
      commit("SET_LOGGED_IN", loggedIn);
    },
    setUserData({ commit }, userData) {
      commit("SET_USER", userData);
    },
  }
});
